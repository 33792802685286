<template>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>上传与查看教程</title>
    </head>

    <body
        style="background-image: url('http://cdn.gusui.site/public/bg1.jpg');background-size: cover; background-repeat: no-repeat;">
        <center>
            <b>
                <h1 style="color: red;">班级相册使用教程</h1>
            </b>
            <h2>班级时刻链接</h2>
            <h3>提示：上传照片需登录，请使用班级官网账号登录</h3>
            <img src="http://cdn.gusui.site/myclass/guide/u1.png" alt="" srcset="" style="width: 50%;">
            <br>
            <a style="font-size: larger;"
                href="https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E7%8F%AD%E7%BA%A7%E6%97%B6%E5%88%BB">点击打开班级时刻</a>
            <hr>

            <h2>班级相册登录链接</h2>
            <h3>提示：用此链接登录，需手动按照上方图片选择文件夹</h3>
            <a style="font-size: larger;"
                href="https://account.gusui.site/login/oauth/authorize?client_id=daabea6f1613a8ec2528&redirect_uri=http%3A%2F%2Fdownload.gusui.site%2Fapi%2Fauth%2Fsso_get_token&response_type=code&scope=profile&state=https%3A%2F%2Faccount.gusui.site">点击登录班级相册</a>
            <hr>

            <h2>上传教程（共4页）</h2>
            <h3>提示：手机可双指放大</h3>
            <img src="http://cdn.gusui.site/myclass/guide/a1.png" alt="" srcset="" style="width: 95%;">
            <p>1/4</p>
            <br>
            <img src="http://cdn.gusui.site/myclass/guide/a2.png" alt="" srcset="" style="width: 95%;">
            <p>2/4</p>
            <br>
            <img src="http://cdn.gusui.site/myclass/guide/a3.png" alt="" srcset="" style="width: 95%;">
            <p>3/4</p>
            <br>
            <img src="http://cdn.gusui.site/myclass/guide/a4.png" alt="" srcset="" style="width: 95%;">
            <p>4/4</p>
            <hr>

            <h2>大图展示教程（共1页）</h2>
            <h3>提示：如发生卡顿可使用网格视图</h3>
            <img src="http://cdn.gusui.site/myclass/guide/b1.png" alt="" srcset="" style="width: 95%;">
            <p>1/1</p>
        </center>
    </body>
</template>
<!-- <script>
import { ElLoading } from 'element-plus'
const loadingInstance = ElLoading.service({ fullscreen: true })
document.onreadystatechange = function () {
  if (document.readyState == "complete") {
    loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
  }
}
</script > -->