import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import "./css/main.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/dark/css-vars.css'
import axios from "axios";
import VueHead from 'vue-head'
import Cookies from 'js-cookie'
// import VueRouter from 'vue-router';


import router from './router.js'; // 引入路由配置文件  
// import Vue from 'vue';  
// import VueRouter from 'vue-router';



axios.defaults.withCredentials = true;
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(ElementPlus);
app.use(Cookies);
app.use(router);
app.use(VueHead)
app.mount("#app");