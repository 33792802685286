<template>
    <head>
    </head>

    <div class="common-layout">

        <el-container>
            <!-- 顶部菜单 -->
            <el-affix>
        <el-header>
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <!-- <div class="header-index"> -->
            <!-- 网站图标 -->
            <img src="../img/icon.png" alt="" srcset="" class="el-header-logo">

            <!-- <div class="flex-grow" /> -->
            <!-- 菜单 -->
            <el-menu-item index="1" onclick="window.location.href = '#/' ">首页</el-menu-item>
            <el-menu-item index="2" onclick="window.location.href = '#/mingyan'">名言</el-menu-item>
            <el-menu-item index="3" onclick="window.location.href = '#/yigeng'">一梗</el-menu-item>
            <el-menu-item index="4"
              onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E7%8F%AD%E7%BA%A7%E6%97%B6%E5%88%BB')">班级时刻</el-menu-item>
            <el-menu-item index="5" onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E4%BD%9C%E4%B8%9A%E5%A2%99')">作业墙</el-menu-item>
            <el-menu-item index="6"
              onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E6%96%87%E4%BB%B6%E5%BA%93')">文件库</el-menu-item>
            <el-sub-menu index="7">
              <template #title>生态</template>
              <el-menu-item index="7-1" onclick="window.open ('https://dsz.hello8693.xyz')"><el-space wrap>电视猪
                  <TopRight style="width: 1em; height: 1em; margin-right: 8px;" />
                </el-space></el-menu-item>
              <el-menu-item index="7-2" onclick="window.open ('https://www.gusui.site')"><el-space wrap>谷穗社区工作室
                  <TopRight style="width: 1em; height: 1em; margin-right: 8px;" />
                </el-space></el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="8">
              <template #title>关于</template>
              <el-menu-item index="8-1" onclick="window.location.href = '#/update'">更新日志</el-menu-item>
              <el-menu-item index="8-2"
                onclick="window.open ('https://cdn.gusui.site/public/ToS.html')">用户条款</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="9">
              <template #title>用班级官网账户登录到</template>
              <el-menu-item index="9-1" onclick="window.open ('https://blog.gusui.site/?option=oauthredirect&app_name=gusui' )">博客/论坛</el-menu-item>
              <el-menu-item index="9-2"
              onclick="window.open ('https://account.gusui.site/login/oauth/authorize?client_id=daabea6f1613a8ec2528&redirect_uri=http%3A%2F%2Fdownload.gusui.site%2Fapi%2Fauth%2Fsso_get_token&response_type=code&scope=profile&state=https%3A%2F%2Faccount.gusui.site' )">班级时刻/文件库/作业墙</el-menu-item>
            </el-sub-menu>
            <!-- <el-space wrap :size="30">
              <el-switch v-model="value1" size="large" :active-action-icon="Cloudy" :inactive-action-icon="Sunny" />
            </el-space> -->

            <!-- </div> -->
            <!-- <div class="space"></div> -->
            <div class="avatar">
              <br>
              <el-button type="primary"
                onclick="window.open ('https://account.gusui.site/login/gusui' )">
                登录
                <!-- <el-icon>
                  <TopRight />
                </el-icon> -->
              </el-button>
              <el-button class="button"
                onclick="window.open ('https://account.gusui.site/signup/gusui' )">
                注册
                <!-- <el-icon>
                  <TopRight />
                </el-icon> -->
              </el-button>
              <!-- <el-avatar :size="50" :src="squareUrl" class="radius" />? -->
              <!-- shape="square" -->

            </div>
          </el-menu>


        </el-header>

      </el-affix>
            <br />

            <!-- 主体 -->

            <el-main>
                <center>
                    <br>
                    <h1 style="font-size:3rem">每日名言</h1>
                    <br>
                </center>
                <br>
                <el-card class="box-card" style="min-width:370px">
                    <br>
                    <h3>添加名言</h3>
                    <br>
                    <el-form :model="form" style="width:100%" action="https://api.gusui.site/v1/add_a_mingyan"
                        method="POST">
                        <el-form-item>
                            <el-text>内容</el-text>
                            <el-input v-model="from.text" name="text" clearable />
                        </el-form-item>
                        <el-form-item>
                            <el-text>描述</el-text>
                            <el-input v-model="from.describe" name="describe" clearable />
                        </el-form-item>
                        <el-form-item>
                            <br><br>
                            <!-- <input type="submit" value="Submit"> -->
                            <!-- <el-button class="el-button--primary" type="submit">提交</el-button> -->
                            <input class="el-button--primary el-button submit" type="submit" value="提交" style="width:100%"/>
                            <!-- <el-button type="primary" @click="onSubmit" style="width:100%">登录</el-button> -->
                        </el-form-item>
                    </el-form>
                </el-card>
                <br>
                <!-- <h3>数据加载较慢（约3秒），请耐心等待</h3> -->
                <h4>如果页面长时间未响应或出现错误，请尝试
                    <el-button class="button" onclick="window.location.reload();">
                        刷新
                        <el-icon>
                            <Refresh />
                        </el-icon>
                    </el-button>
                </h4>
                <br />
                <el-table v-loading="loading" :data="mingyan" stripe style="width: 100%">
                    <el-table-column prop="id" label="ID"/>
                    <el-table-column prop="text" label="内容" />
                    <el-table-column prop="describe" label="描述" />
                </el-table>
            </el-main>

            <!-- 底部 -->
            <el-footer>班级官网 | Copyright © 2020-2024 谷穗社区工作室&emsp;
                <a target="_blank" href="https://beian.miit.gov.cn">鲁ICP备2022032319号-3</a>&emsp;
                <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=37069302888890">鲁公网安备
                    37069302888890号</a></el-footer>
        </el-container>
    </div>

    <el-backtop :right="20" :bottom="20" />
</template>
  
<script>
import axios from 'axios'
// import { ElLoading } from 'element-plus'
// const loadingInstance = ElLoading.service({ fullscreen: false })
export default {
    name: 'App',
    data: function () {
        return {
            id: [],
            text: [],
            describe: [],
            mingyan: [],
            from: {
                text: '',
                describe: ''
            }
        }
    },
    mounted() {
        // console.log(this.$route.query.type)
        // this.type=this.$route.query.type;
        document.title = '烟台开发区实验中学 - 2022级九班',
            axios.defaults.withCredentials = true;
        axios
            // .get('http://127.0.0.1:8099/v1/site_message')
            .get('https://api.gusui.site/v1/get_mingyan_id')
            .then(response => (this.id = response.data.split("&")))
            .catch(function (error) { // 请求失败处理
                console.log(error);
                window.location.reload();
            });
        axios
            // .get('http://127.0.0.1:8099/v1/site_message')
            .get('https://api.gusui.site/v1/get_mingyan_text')
            .then(response => (this.text = response.data.split("&")))
            .catch(function (error) { // 请求失败处理
                console.log(error);
                window.location.reload();
            });
        axios
            // .get('http://127.0.0.1:8099/v1/site_message')
            .get('https://api.gusui.site/v1/get_mingyan_describe')
            .then(response => (this.describe = response.data.split("&")))
            .catch(function (error) { // 请求失败处理
                console.log(error);
                window.location.reload();
            });
        setTimeout(() => {
            if (this.describe.length === 0) {
                axios
                    // .get('http://127.0.0.1:8099/v1/site_message')
                    .get('https://api.gusui.site/v1/get_mingyan_id')
                    .then(response => (this.id = response.data.split("&")))
                    .catch(function (error) { // 请求失败处理
                        console.log(error);
                    });
                axios
                    // .get('http://127.0.0.1:8099/v1/site_message')
                    .get('https://api.gusui.site/v1/get_mingyan_text')
                    .then(response => (this.text = response.data.split("&")))
                    .catch(function (error) { // 请求失败处理
                        console.log(error);
                    });
                axios
                    // .get('http://127.0.0.1:8099/v1/site_message')
                    .get('https://api.gusui.site/v1/get_mingyan_describe')
                    .then(response => (this.describe = response.data.split("&")))
                    .catch(function (error) { // 请求失败处理
                        console.log(error);
                    });
                setTimeout(() => {
                    this.mingyan = this.id.map((date, index) => ({
                        id: this.id[index],
                        text: this.text[index],
                        describe: this.describe[index]
                    }));
                    // loadingInstance.close();
                }, 2000);
            } else {
                this.mingyan = this.id.map((date, index) => ({
                    id: this.id[index],
                    text: this.text[index],
                    describe: this.describe[index]
                }));
                // loadingInstance.close();
            }
            // console.log("111")
            // console.log(this.id)
            // console.log(this.text)
            // console.log(this.describe)
            // console.log(this.mingyan)

        }, 500);

    },
}
</script>
  <!-- <script>
  import { ElLoading } from 'element-plus'
  const loadingInstance = ElLoading.service({ fullscreen: true })
  document.onreadystatechange = function () {
    if (document.readyState == "complete") {
      loadingInstance.close();
    }
  }
  </script > -->
  <!-- <script setup>
  // import { ref, computed } from 'vue'
  // import Home from './Home.vue'
  // import About from './About.vue'
  // import NotFound from './NotFound.vue'
  // const routes = {
  //   '/': Home,
  //   '/about': About
  // }
  // const currentPath = ref(window.location.hash)
  // window.addEventListener('hashchange', () => {
  //   currentPath.value = window.location.hash
  // })
  // const currentView = computed(() => {
  //   return routes[currentPath.value.slice(1) || '/'] || NotFound
  // })
  </script> -->
  <!-- <script setup>
  import { ref } from 'vue'
  import { Cloudy, Sunny, Right } from '@element-plus/icons-vue'
  const value1 = ref(false)
  </script> -->