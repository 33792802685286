// import Vue from 'vue';  
// import VueRouter from 'vue-router';  
import HomeComponent from './components/homeComponent.vue';  
import UploadComponent from './components/uploadComponent.vue';  
import MingyanComponent from './components/mingyanComponent.vue'; 
import GengComponent from './components/gengComponent.vue';   
import UpdateComponent from './components/updateComponent.vue';  
// import ResultComponent from './components/resultComponent.vue';  
// import SetPwdComponent from './components/setPwd.vue';  
// import LoginComponent from './components/loginComponent.vue';  
// import RegisterComponent from './components/registerComponent.vue';  
// import UserComponent from './components/userComponent.vue'; 
// import NotFoundComponent from './components/NotFound.vue';  

import {createRouter,createWebHashHistory} from 'vue-router';

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            component:HomeComponent
        },
        {
            path: '/upload',
            component:UploadComponent
        },
        {
            path: '/mingyan',
            component:MingyanComponent
        },
        {
            path: '/update',
            component:UpdateComponent
        },
        {
            path: '/yigeng',
            component:GengComponent
        },
        // {
        //     path: '/result/:type/:title/:msg/:url/:key',
        //     component:ResultComponent
        // },
        // {
        //     path: '/setpwd',
        //     component:SetPwdComponent
        // },
        // {
        //     path: '/login',
        //     component:LoginComponent
        // },
        // {
        //     path: '/register',
        //     component:RegisterComponent
        // },
        // {
        //     path: '/user',
        //     component:UserComponent
        // },
    ]
});
  

export default router;