<template>
  <head>
    <link rel="manifest" href="./manifest.json">
  </head>

  <div class="common-layout">

    <el-container>
      <!-- 顶部菜单 -->
      <el-affix>
        <el-header>
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <!-- <div class="header-index"> -->
            <!-- 网站图标 -->
            <img src="../img/icon.png" alt="" srcset="" class="el-header-logo">

            <!-- <div class="flex-grow" /> -->
            <!-- 菜单 -->
            <el-menu-item index="1" onclick="window.location.href = '#/' ">首页</el-menu-item>
            <el-menu-item index="2" onclick="window.location.href = '#/mingyan'">名言</el-menu-item>
            <el-menu-item index="3" onclick="window.location.href = '#/yigeng'">一梗</el-menu-item>
            <el-menu-item index="4"
              onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E7%8F%AD%E7%BA%A7%E6%97%B6%E5%88%BB')">班级时刻</el-menu-item>
            <el-menu-item index="5" onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E4%BD%9C%E4%B8%9A%E5%A2%99')">作业墙</el-menu-item>
            <el-menu-item index="6"
              onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E6%96%87%E4%BB%B6%E5%BA%93')">文件库</el-menu-item>
            <el-sub-menu index="7">
              <template #title>生态</template>
              <el-menu-item index="7-1" onclick="window.open ('https://dsz.hello8693.xyz')"><el-space wrap>电视猪
                  <TopRight style="width: 1em; height: 1em; margin-right: 8px;" />
                </el-space></el-menu-item>
              <el-menu-item index="7-2" onclick="window.open ('https://www.gusui.site')"><el-space wrap>谷穗社区工作室
                  <TopRight style="width: 1em; height: 1em; margin-right: 8px;" />
                </el-space></el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="8">
              <template #title>关于</template>
              <el-menu-item index="8-1" onclick="window.location.href = '#/update'">更新日志</el-menu-item>
              <el-menu-item index="8-2"
                onclick="window.open ('https://cdn.gusui.site/public/ToS.html')">用户条款</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="9">
              <template #title>用班级官网账户登录到</template>
              <el-menu-item index="9-1" onclick="window.open ('https://blog.gusui.site/?option=oauthredirect&app_name=gusui' )">博客/论坛</el-menu-item>
              <el-menu-item index="9-2"
              onclick="window.open ('https://account.gusui.site/login/oauth/authorize?client_id=daabea6f1613a8ec2528&redirect_uri=http%3A%2F%2Fdownload.gusui.site%2Fapi%2Fauth%2Fsso_get_token&response_type=code&scope=profile&state=https%3A%2F%2Faccount.gusui.site' )">班级时刻/文件库/作业墙</el-menu-item>
            </el-sub-menu>
            <!-- <el-space wrap :size="30">
              <el-switch v-model="value1" size="large" :active-action-icon="Cloudy" :inactive-action-icon="Sunny" />
            </el-space> -->

            <!-- </div> -->
            <!-- <div class="space"></div> -->
            <div class="avatar">
              <br>
              <el-button type="primary"
                onclick="window.open ('https://account.gusui.site/login/gusui' )">
                登录
                <!-- <el-icon>
                  <TopRight />
                </el-icon> -->
              </el-button>
              <el-button class="button"
                onclick="window.open ('https://account.gusui.site/signup/gusui' )">
                注册
                <!-- <el-icon>
                  <TopRight />
                </el-icon> -->
              </el-button>
              <!-- <el-avatar :size="50" :src="squareUrl" class="radius" />? -->
              <!-- shape="square" -->

            </div>
          </el-menu>


        </el-header>

      </el-affix>
      <br />
      <div class="video">
        <div class="name-parent">

          <div class="name">
            <h1>2022级九班</h1>
            <h1 class="describe">不卑不亢 奋发向上 非比寻常 九班最强</h1>
          </div>
        </div>
        <img class="top" src="https://cdn.gusui.site/myclass/img/head_map_pc.png" alt="" srcset="" />
        <!-- <iframe class="top" src="//player.bilibili.com/player.html?aid=555770254&bvid=BV1Ae4y1R7re&cid=770510583&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe> -->
        <!-- <video src="../video/home_video.mp4" autoplay loop class="top"></video> -->
      </div>
      <div></div>

      <img class="m" src="https://cdn.gusui.site/myclass/img/head_map2.png" alt="" srcset="" style="width:100vw;" />
      <!-- 主体 -->

      <el-main>
        <br />
        <div class="pc">
          
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span style="color:red;"><b>地理会考倒计时</b></span>
                <!-- <el-button class="button" onclick="window.open ('#/yigeng')">点此查看更多&nbsp;<el-icon>
                    <Right />
                  </el-icon></el-button> -->
              </div>
            </template>
            <center>
              <div class="text item">
                <br>
                <h1 style="color:red;">
                  <span class="tian">加载中...</span> <span class="shi">加载中...</span> <span class="fen">加载中...</span> <span class="miao">加载中...</span>
                </h1>
                <br>
              </div>
            </center>
          </el-card>
          <br><br>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-card class="box-card">
                <template #header>
                  <div class="card-header">
                    <span>学校介绍</span>

                  </div>
                </template>
                <div class="text item">
                  &emsp;&emsp;烟台经济技术开发区实验中学创建于2003年，是一所有着浓郁的学习氛围和追求卓越的年轻学校。现拥有两个校区52个教学班，188名教职工，在校学生2900多名。学校环境清雅怡人、设施齐备、教风浓厚、学风卓越。建校以来，在"砺志尚德，博学求真"的校训引领下，全体师生锐意进取，坚持"以尊重为前提、以规范为条件、以和谐为核心、以发展为目的"的办学思想，"以开发学生潜能，发展学生个性，让每一个学生得到全面充分和谐的发展"为培育目标，积极探索创新课堂教学模式，大胆开展课堂改革，促进学生自主学习、主动发展，构建崭新的和谐高效课堂。经过15年不断实践和创新，烟台经济技术开发区实验中学已初步形成了学校发展的"六大优势":环境优势、理念优势、质量优势、管理优势、师资优势和学校品牌优势。不但形成了自己独特的办学特色，更取得了优异的的教育教学成绩，优生培养卓有成效，培养出了包括清华、北大在内的一批又一批优秀毕业生。得到了社会各界的广泛赞誉。
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">


              <el-card class="box-card">
                <template #header>
                  <div class="card-header">
                    <span>班级介绍</span>

                  </div>
                </template>
                <div class="text item">
                  &emsp;&emsp;我们以年华作笔，以汗水为墨，从一张又一张白纸上挥洒出红橙黄绿蓝靛紫，我们是在阳光下折射的班级——初二九班。五十张来自内心的笑脸，热情洋溢，绮丽的季节里盛开出灿烂与光华。五十声来自心灵的呼喊，充满力度，恬淡的天空中可与雷声媲美。在运动会上，团结拼搏到底是我们凝聚力的象征；在学习上，破釜沉舟，“我们不是最好，但我们力求更好”是我们的宣言；在艺术画展上，自信与勇气成为我们一战到底的资本。无论那一项，必然有我们取得的必须成绩。我们正向慎独之风一步一步迈进，这股高尚之风将会吹遍五十棵阳光下茁壮成长的小树，待到参天大树时，将会是一片浓荫。今日我们种下一棵树，他日必将丰收人生中最富有好处的硕果。我们是初二九班，我们还要执笔续写新一年的篇章。
                </div>
              </el-card>
            </el-col>
          </el-row>
          <br><br>
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>每日名言</span>
                <el-button class="button" onclick="window.open ('#/mingyan')">点此查看更多&nbsp;<el-icon>
                    <Right />
                  </el-icon></el-button>
              </div>
            </template>
            <center>
              <div class="text item">
                <br>
                <h1>
                  「 {{ mingyan }} 」
                </h1>
                <br>
              </div>
            </center>
          </el-card>
          <br><br>
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>一梗</span>
                <el-button class="button" onclick="window.open ('#/yigeng')">点此查看更多&nbsp;<el-icon>
                    <Right />
                  </el-icon></el-button>
              </div>
            </template>
            <center>
              <div class="text item">
                <br>
                <h1>
                  /* {{ geng }} */
                </h1>
                <br>
              </div>
            </center>
          </el-card>
          

          <br><br><br>
          <h1>&nbsp;小组件</h1>
          <br>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-card class="box-card">
                <template #header>
                  <div class="card-header">
                    <span><b>博客</b></span>
                    <el-button class="button" onclick="window.open ('https://blog.gusui.site/')">点此进入&nbsp;<el-icon>
                        <Right />
                      </el-icon>
                    </el-button>
                  </div>
                </template>
                <div class="text item">
                  &emsp;&emsp;这里是我们分享学习心得、展示才华和互动交流的平台。在这里，你可以浏览我们的课程笔记、实践项目和班级动态，也可以参与讨论，分享你的观点。期待你的加入，一起书写我们的班级故事！
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <template #header>
                  <div class="card-header">
                    <span><b>论坛</b></span>
                    <el-button class="button" onclick="window.open ('https://blog.gusui.site/forums')">点此进入&nbsp;<el-icon>
                        <Right />
                      </el-icon>
                    </el-button>
                  </div>
                </template>
                <div class="text item">
                  &emsp;&emsp;自由交流，畅所欲言。分享学习心得，结识新朋友。你的声音，我们倾听。快来加入我们，共同书写精彩篇章！
                </div>
              </el-card>
            </el-col>
          </el-row>

          <br>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-card class="box-card">
                <template #header>
                  <div class="card-header">
                    <span><b>文件库</b></span>
                    <el-button class="button"
                      onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E6%96%87%E4%BB%B6%E5%BA%93')">点此进入&nbsp;<el-icon>
                        <Right />
                      </el-icon>
                    </el-button>
                  </div>
                </template>
                <div class="text item">
                  &emsp;&emsp;一键上传，一键下载。课程资料、作业文件，应有尽有。高效便捷，助力学习。快来探索，共享知识！
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <template #header>
                  <div class="card-header">
                    <span><b>作业墙</b></span>
                    <el-button class="button" onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E4%BD%9C%E4%B8%9A%E5%A2%99')">点此进入&nbsp;<el-icon>
                        <Right />
                      </el-icon>
                    </el-button>
                  </div>
                </template>
                <div class="text item">
                  &emsp;&emsp;快速查看各科作业，实时跟踪学习进度。一目了然，轻松管理，让学习过程更加高效和有序。快来浏览我们的作业墙，与同学们一起共同进步吧！
                </div>
              </el-card>
            </el-col>
          </el-row>

        </div>

        <!--手机端-->
        <div class="m">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span style="color:red;"><b>地理会考倒计时</b></span>
                <!-- <el-button class="button" onclick="window.open ('#/yigeng')">点此查看更多&nbsp;<el-icon>
                    <Right />
                  </el-icon></el-button> -->
              </div>
            </template>
            <center>
              <div class="text item">
                <br>
                <h1 style="color:red;">
                  <span class="tian2">加载中...</span> <span class="shi2">加载中...</span> <span class="fen2">加载中...</span> <span class="miao2">加载中...</span>
                </h1>
                <br>
              </div>
            </center>
          </el-card>
          <br>
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>学校介绍</span>

              </div>
            </template>
            <div class="text item">
              &emsp;&emsp;烟台经济技术开发区实验中学创建于2003年，是一所有着浓郁的学习氛围和追求卓越的年轻学校。现拥有两个校区52个教学班，188名教职工，在校学生2900多名。学校环境清雅怡人、设施齐备、教风浓厚、学风卓越。建校以来，在"砺志尚德，博学求真"的校训引领下，全体师生锐意进取，坚持"以尊重为前提、以规范为条件、以和谐为核心、以发展为目的"的办学思想，"以开发学生潜能，发展学生个性，让每一个学生得到全面充分和谐的发展"为培育目标，积极探索创新课堂教学模式，大胆开展课堂改革，促进学生自主学习、主动发展，构建崭新的和谐高效课堂。经过15年不断实践和创新，烟台经济技术开发区实验中学已初步形成了学校发展的"六大优势":环境优势、理念优势、质量优势、管理优势、师资优势和学校品牌优势。不但形成了自己独特的办学特色，更取得了优异的的教育教学成绩，优生培养卓有成效，培养出了包括清华、北大在内的一批又一批优秀毕业生。得到了社会各界的广泛赞誉。
            </div>
          </el-card>

          <br>
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>班级介绍</span>

              </div>
            </template>
            <div class="text item">
              &emsp;&emsp;我们以年华作笔，以汗水为墨，从一张又一张白纸上挥洒出红橙黄绿蓝靛紫，我们是在阳光下折射的班级——初二九班。五十张来自内心的笑脸，热情洋溢，绮丽的季节里盛开出灿烂与光华。五十声来自心灵的呼喊，充满力度，恬淡的天空中可与雷声媲美。在运动会上，团结拼搏到底是我们凝聚力的象征；在学习上，破釜沉舟，“我们不是最好，但我们力求更好”是我们的宣言；在艺术画展上，自信与勇气成为我们一战到底的资本。无论那一项，必然有我们取得的必须成绩。我们正向慎独之风一步一步迈进，这股高尚之风将会吹遍五十棵阳光下茁壮成长的小树，待到参天大树时，将会是一片浓荫。今日我们种下一棵树，他日必将丰收人生中最富有好处的硕果。我们是初二九班，我们还要执笔续写新一年的篇章。
            </div>
          </el-card>
          <br>
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>每日名言</span>
                <el-button class="button" onclick="window.open ('#/mingyan')">点此查看更多&nbsp;<el-icon>
                    <Right />
                  </el-icon></el-button>
              </div>
            </template>
            <center>
              <div class="text item">
                <br>
                <h1>
                  「 {{ mingyan }} 」
                </h1>
                <br>
              </div>
            </center>
          </el-card>
          <br>
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>一梗</span>
                <el-button class="button" onclick="window.open ('#/yigeng')">点此查看更多&nbsp;<el-icon>
                    <Right />
                  </el-icon></el-button>
              </div>
            </template>
            <center>
              <div class="text item">
                <br>
                <h1>
                  /* {{ geng }} */
                </h1>
                <br>
              </div>
            </center>
          </el-card>

          <br><br><br>
          <h1>&nbsp;小组件</h1>
          <br>

          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span><b>博客</b></span>
                <el-button class="button" onclick="window.open ('https://blog.gusui.site/')">点此进入&nbsp;<el-icon>
                    <Right />
                  </el-icon>
                </el-button>
              </div>
            </template>
            <div class="text item">
              &emsp;&emsp;这里是我们分享学习心得、展示才华和互动交流的平台。在这里，你可以浏览我们的课程笔记、实践项目和班级动态，也可以参与讨论，分享你的观点。期待你的加入，一起书写我们的班级故事！
            </div>
          </el-card>
          <br>
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span><b>论坛</b></span>
                <el-button class="button" onclick="window.open ('https://blog.gusui.site/forums')">点此进入&nbsp;<el-icon>
                    <Right />
                  </el-icon>
                </el-button>
              </div>
            </template>
            <div class="text item">
              &emsp;&emsp;自由交流，畅所欲言。分享学习心得，结识新朋友。你的声音，我们倾听。快来加入我们，共同书写精彩篇章！
            </div>
          </el-card>

          <br>

          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span><b>文件库</b></span>
                <el-button class="button"
                  onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E6%96%87%E4%BB%B6%E5%BA%93')">点此进入&nbsp;<el-icon>
                    <Right />
                  </el-icon>
                </el-button>
              </div>
            </template>
            <div class="text item">
              &emsp;&emsp;一键上传，一键下载。课程资料、作业文件，应有尽有。高效便捷，助力学习。快来探索，共享知识！
            </div>
          </el-card>
          <br>
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span><b>作业墙</b></span>
                <el-button class="button" onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E4%BD%9C%E4%B8%9A%E5%A2%99')">点此进入&nbsp;<el-icon>
                    <Right />
                  </el-icon>
                </el-button>
              </div>
            </template>
            <div class="text item">
              &emsp;&emsp;快速查看各科作业，实时跟踪学习进度。一目了然，轻松管理，让学习过程更加高效和有序。快来浏览我们的作业墙，与同学们一起共同进步吧！
            </div>
          </el-card>
        </div>


        <br><br><br>
        <h1>&nbsp;班级时刻&nbsp;
          <br><br>
          <el-button class="button"
            onclick="window.open ('https://account.gusui.site/login/oauth/authorize?client_id=daabea6f1613a8ec2528&redirect_uri=http%3A%2F%2Fdownload.gusui.site%2Fapi%2Fauth%2Fsso_get_token&response_type=code&scope=profile&state=https%3A%2F%2Faccount.gusui.site' )">
            登录班级相册
            <el-icon>
              <TopRight />
            </el-icon>
          </el-button>
          <el-button class="button"
            onclick="window.open('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E7%8F%AD%E7%BA%A7%E6%97%B6%E5%88%BB')">
            查看更多
            <el-icon>
              <TopRight />
            </el-icon>
          </el-button>
          <el-button class="button" onclick="window.open('#/upload')">
            查看教程
            <el-icon>
              <TopRight />
            </el-icon>
          </el-button>
        </h1>
        <br>
        <iframe
          src="https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E7%8F%AD%E7%BA%A7%E6%97%B6%E5%88%BB"
          frameborder="0" width="100%" style=" border-radius: 10px;" height="600px"></iframe>
        <!-- <el-carousel :interval="5000" arrow="always">
          <el-carousel-item>
            <img src="../img/head_map.png">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../img/head_map.png">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../img/head_map.png">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../img/head_map.png">
          </el-carousel-item>
        </el-carousel> -->
        <br><br>
        <center><iframe frameborder="no" border="0" marginwidth="0" marginheight="0" width="100%" height="450"
            src="https://music.163.com/outchain/player?type=0&amp;id=8193446357&amp;auto=0&amp;height=430"></iframe>
        </center>

        <!-- <section data-v-68e706e5="" data-v-092338f0="" class="section section-ips"><img data-v-68e706e5=""
            data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-python.6e970c78.png" class="ip"><img
            data-v-68e706e5="" data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-excel.8bd8607a.png"
            class="ip"><img data-v-68e706e5="" data-v-092338f0=""
            src="//ali.bczcdn.com/nocturne/static/img/ip-data-analysis.819cc42c.png" class="ip"></section>

        <section data-v-68e706e5="" data-v-092338f0="" class="section section-ips"><img data-v-68e706e5=""
            data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-python.6e970c78.png" class="ip"><img
            data-v-68e706e5="" data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-excel.8bd8607a.png"
            class="ip"><img data-v-68e706e5="" data-v-092338f0=""
            src="//ali.bczcdn.com/nocturne/static/img/ip-data-analysis.819cc42c.png" class="ip"></section>
        <section data-v-68e706e5="" data-v-092338f0="" class="section section-ips"><img data-v-68e706e5=""
            data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-python.6e970c78.png" class="ip"><img
            data-v-68e706e5="" data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-excel.8bd8607a.png"
            class="ip"><img data-v-68e706e5="" data-v-092338f0=""
            src="//ali.bczcdn.com/nocturne/static/img/ip-data-analysis.819cc42c.png" class="ip"></section>
        <section data-v-68e706e5="" data-v-092338f0="" class="section section-ips"><img data-v-68e706e5=""
            data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-python.6e970c78.png" class="ip"><img
            data-v-68e706e5="" data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-excel.8bd8607a.png"
            class="ip"><img data-v-68e706e5="" data-v-092338f0=""
            src="//ali.bczcdn.com/nocturne/static/img/ip-data-analysis.819cc42c.png" class="ip"></section> -->
        <!-- <iframe src="https://account.gusui.site/api/get-account" id="user"></iframe> -->
      </el-main>

      <!-- 底部 -->
      <el-footer>班级官网 | Copyright © 2020-2024 谷穗社区工作室&emsp;
        <a target="_blank" href="https://beian.miit.gov.cn">鲁ICP备2022032319号-3</a>&emsp;
        <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=37069302888890">鲁公网安备
          37069302888890号</a></el-footer>
    </el-container>
  </div>

  <el-backtop :right="20" :bottom="20" />
</template>

<script>
import axios from 'axios'
// import Cookie from 'js-cookie'
// import * as Setting from "./Setting";
export default {
  name: 'App',
  data: function () {
    return {
      mingyan: '获取中...',
      geng: '获取中...',
      user: '',
      avatar: '',
      links: {

      }
    }
  },
  mounted() {
    // console.log(this.$route.query.type)
    // this.type=this.$route.query.type;
    document.title = '烟台开发区实验中学 - 2022级九班',
      // setTimeout(() => {
      //     this.iframe = document.getElementById("user");
      //     // this.iframe.onload = function() {
      //     this.iframe=this.iframe.contentDocument
      //  // 获取iframe的contentWindow对象
      // //  this.iframe = this.iframe.contentDocument || this.iframe.contentWindow.document;

      //  // 获取iframe的document对象
      // //  this.iframe = this.iframe.document;

      //  // 输出iframe的内容
      //  console.log(this.iframe.body.pre);
      // };
      // this.iframe = this.iframe.contentDocument || this.iframe.contentWindow.document;
      // this.iframe = this.iframe.body.innerHTML;
      // console.log(1111);
      // console.log(this.iframe);
      //   },2000)
      axios.defaults.withCredentials = true;
      axios
      // .get('http://127.0.0.1:8099/v1/site_message')
      .get('https://api.gusui.site/v1/get_a_mingyan')
      .then(response => (this.mingyan = response.data))
      .catch(function (error) { console.log(error); });
    axios
      // .get('http://127.0.0.1:8099/v1/site_message')
      .get('https://api.gusui.site/v1/get_a_geng')
      .then(response => (this.geng = response.data))
      .catch(function (error) { console.log(error); });
      axios
      // .get('http://127.0.0.1:8099/v1/site_message')
      .get('https://api.gusui.site/v1/get_a_mingyan')
      .then(response => (this.mingyan = response.data))
      .catch(function (error) { console.log(error); });
    axios
      // .get('http://127.0.0.1:8099/v1/site_message')
      .get('https://api.gusui.site/v1/get_a_geng')
      .then(response => (this.geng = response.data))
      .catch(function (error) { console.log(error); });
// 获取元素
        var tian = document.querySelector('.tian')
        var shi = document.querySelector('.shi')
        var fen = document.querySelector('.fen')
        var miao = document.querySelector('.miao')
        var tian2 = document.querySelector('.tian2')
        var shi2 = document.querySelector('.shi2')
        var fen2 = document.querySelector('.fen2')
        var miao2 = document.querySelector('.miao2')

        //春节日期
        var NewYearTime = +new Date('2024-06-12 15:30:00')

        GetTime()
        // 自动重复调用函数,间隔一秒
        setInterval(GetTime, 1000)

        // 获取时间函数
        function GetTime() {
            var nowtime = +new Date()                   //返回自1970年1月1日至今的总毫秒数
            var times = (NewYearTime - nowtime) / 1000 //得到用户输入的时间-当前时间的毫秒值 / 1000 = 剩余秒总数

            var t = parseInt(times / 60 / 60 / 24)  //得到天
            t = t < 10 ? '0' + t : t
            tian.innerHTML = t + ' 天'
            tian2.innerHTML = t + ' 天'
            var h = parseInt(times / 60 / 60 % 24)  //得到小时
            h = h < 10 ? '0' + h : h                //三元运算,如果小于10,就在十位补0,如果大于10 就正常输出
            shi.innerHTML = h + ' 小时'
            shi2.innerHTML = h + ' 小时'
            var m = parseInt(times / 60 % 60)       //取得分钟
            m = m < 10 ? '0' + m : m
            fen.innerHTML = m + ' 分钟'
            fen2.innerHTML = m + ' 分钟'
            var s = parseInt(times % 60)            //取得秒
            s = s < 10 ? '0' + s : s
            miao.innerHTML = s + ' 秒'
            miao2.innerHTML = s + ' 秒'
        }

    // var xhr = new XMLHttpRequest(); // 创建XMLHttpRequest对象
    // xhr.open("GET", "https://account.gusui.site/api/get-account", false); // 设置请求类型、URL和是否为异步请求（false表示同步）
    // xhr.send(null); // 发送请求
    // if (xhr.status === 200) { // 判断请求状态码是否为成功
    //   console.log(xhr.responseText); // 输出服务器返回的数据
    // } else {
    //   console.error("Request failed with status code: ", xhr.status);
    // }
    // axios
    //   // .get('http://127.0.0.1:8099/v1/site_message')
    //   .get('/api/user')
    //   .then(response => (this.user = response.data))
    //   .catch(function (error) { // 请求失败处理
    //     console.log(error);
    //   });
    // console.log(this.user);
    // setTimeout(() => {
    //   console.log(this.user)
    //   try {
    //     console.log("jsonObj");
    //     var jsonObj = JSON.parse(JSON.stringify(this.user));
    //     // Cookie.set('casdoor_session_id', '123')
    //     console.log(Cookie.get());
    //     console.log(jsonObj);
    //     if(jsonObj.status==='error'){
    //       this.avatar='no';
    //     }else{
    //       this.avatar='yes';
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }, 1000);
  }
}
</script>
<!-- <script>
import { ElLoading } from 'element-plus'
const loadingInstance = ElLoading.service({ fullscreen: true })
document.onreadystatechange = function () {
  if (document.readyState == "complete") {
    loadingInstance.close();
  }
}
</script > -->
<!-- <script setup>
// import { ref, computed } from 'vue'
// import Home from './Home.vue'
// import About from './About.vue'
// import NotFound from './NotFound.vue'
// const routes = {
//   '/': Home,
//   '/about': About
// }
// const currentPath = ref(window.location.hash)
// window.addEventListener('hashchange', () => {
//   currentPath.value = window.location.hash
// })
// const currentView = computed(() => {
//   return routes[currentPath.value.slice(1) || '/'] || NotFound
// })
</script> -->
<!-- <script setup>
import { ref } from 'vue'
import { Cloudy, Sunny, Right } from '@element-plus/icons-vue'
const value1 = ref(false)
</script> -->