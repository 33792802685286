<template>
    <head>
    </head>

    <div class="common-layout">

        <el-container>
            <!-- 顶部菜单 -->
            <el-affix>
        <el-header>
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <!-- <div class="header-index"> -->
            <!-- 网站图标 -->
            <img src="../img/icon.png" alt="" srcset="" class="el-header-logo">

            <!-- <div class="flex-grow" /> -->
            <!-- 菜单 -->
            <el-menu-item index="1" onclick="window.location.href = '#/' ">首页</el-menu-item>
            <el-menu-item index="2" onclick="window.location.href = '#/mingyan'">名言</el-menu-item>
            <el-menu-item index="3" onclick="window.location.href = '#/yigeng'">一梗</el-menu-item>
            <el-menu-item index="4"
              onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E7%8F%AD%E7%BA%A7%E6%97%B6%E5%88%BB')">班级时刻</el-menu-item>
            <el-menu-item index="5" onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E4%BD%9C%E4%B8%9A%E5%A2%99')">作业墙</el-menu-item>
            <el-menu-item index="6"
              onclick="window.open ('https://download.gusui.site/%E4%B8%8B%E8%BD%BD%E7%BA%BF%E8%B7%AF2%20[%E5%A4%A9%E7%BF%BC%E4%BA%91%E7%9B%98%E5%88%86%E6%B5%81]/%E7%8F%AD%E7%BA%A7%E5%AE%98%E7%BD%91/%E6%96%87%E4%BB%B6%E5%BA%93')">文件库</el-menu-item>
            <el-sub-menu index="7">
              <template #title>生态</template>
              <el-menu-item index="7-1" onclick="window.open ('https://dsz.hello8693.xyz')"><el-space wrap>电视猪
                  <TopRight style="width: 1em; height: 1em; margin-right: 8px;" />
                </el-space></el-menu-item>
              <el-menu-item index="7-2" onclick="window.open ('https://www.gusui.site')"><el-space wrap>谷穗社区工作室
                  <TopRight style="width: 1em; height: 1em; margin-right: 8px;" />
                </el-space></el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="8">
              <template #title>关于</template>
              <el-menu-item index="8-1" onclick="window.location.href = '#/update'">更新日志</el-menu-item>
              <el-menu-item index="8-2"
                onclick="window.open ('https://cdn.gusui.site/public/ToS.html')">用户条款</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="9">
              <template #title>用班级官网账户登录到</template>
              <el-menu-item index="9-1" onclick="window.open ('https://blog.gusui.site/?option=oauthredirect&app_name=gusui' )">博客/论坛</el-menu-item>
              <el-menu-item index="9-2"
              onclick="window.open ('https://account.gusui.site/login/oauth/authorize?client_id=daabea6f1613a8ec2528&redirect_uri=http%3A%2F%2Fdownload.gusui.site%2Fapi%2Fauth%2Fsso_get_token&response_type=code&scope=profile&state=https%3A%2F%2Faccount.gusui.site' )">班级时刻/文件库/作业墙</el-menu-item>
            </el-sub-menu>
            <!-- <el-space wrap :size="30">
              <el-switch v-model="value1" size="large" :active-action-icon="Cloudy" :inactive-action-icon="Sunny" />
            </el-space> -->

            <!-- </div> -->
            <!-- <div class="space"></div> -->
            <div class="avatar">
              <br>
              <el-button type="primary"
                onclick="window.open ('https://account.gusui.site/login/gusui' )">
                登录
                <!-- <el-icon>
                  <TopRight />
                </el-icon> -->
              </el-button>
              <el-button class="button"
                onclick="window.open ('https://account.gusui.site/signup/gusui' )">
                注册
                <!-- <el-icon>
                  <TopRight />
                </el-icon> -->
              </el-button>
              <!-- <el-avatar :size="50" :src="squareUrl" class="radius" />? -->
              <!-- shape="square" -->

            </div>
          </el-menu>


        </el-header>

      </el-affix>
            <br />

            <!-- 主体 -->

            <el-main style="min-height:82vh">
                <center>
                    <br>
                    <h1 style="font-size:3rem">更新日志</h1>
                    <br>
                </center>
                <br>
    <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="时间" />
        <el-table-column prop="v" label="更新后版本"  />
        <el-table-column prop="name" label="更新内容"  />
        <el-table-column prop="address" label="贡献者" />
    </el-table>
</el-main>

<!-- 底部 -->
<el-footer>班级官网 | Copyright © 2020-2024 谷穗社区工作室&emsp;
    <a target="_blank" href="https://beian.miit.gov.cn">鲁ICP备2022032319号-3</a>&emsp;
    <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=37069302888890">鲁公网安备
        37069302888890号</a></el-footer>
</el-container>
</div>

<el-backtop :right="20" :bottom="20" />
</template>

<script>
export default {
    name: 'App',
    data: function () {
        return {
            tableData :[
                {
                    date: '2024/2/20',
                    v: 'V3.0',
                    name: '3.0大改版，全面更新架构，全面升级界面',
                    address: '@我',
                },
                {
                    date: '2023/4/14',
                    v: 'V2.0',
                    name: '添加照片墙\n添加文件库\n优化流畅度\n修复部分已知bug',
                    address: '@我@赵英赫@郝老师',
                },
                {
                    date: '2023/3/29',
                    v: 'V1.0',
                    name: 'V1.0',
                    address: '@所有人',
                },
            ]
        }
    },
}
</script>