<!-- <script setup> 
// import { ref, computed } from 'vue'
// import Home from './components/home.vue'
// import LoginComponent from './components/loginComponent.vue'
// import RegisterComponent from './components/registerComponent.vue'
// import SetPwdComponent from './components/setPwd.vue'
// // // import About from './About.vue'
// import NotFound from './components/NotFound.vue'
// import ResultComponent from './components/resultComponent.vue'
// import Vue from 'vue'  
// import VueRouter from 'vue-router'
// const routes = [{ path: '/', component: Home },
//   { path: '/result/:key', component: ResultComponent },
//   { path: '/login', component: LoginComponent },
//   { path: '/register', component: RegisterComponent },
//   { path: '/setpwd', component: SetPwdComponent },
//   ]

// const router = VueRouter.createRouter({
//   // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//   history: VueRouter.createWebHashHistory(),
//   routes, // `routes: routes` 的缩写
// })

// 5. 创建并挂载根实例
// const app = Vue.createApp({})
//确保 _use_ 路由实例使
//整个应用支持路由。
// app.use(router)

// app.mount('#app')
// const routes = {
// //   // 
//   '/': Home,
// //   // 
//   '/result*': ResultComponent,
// //   // 
// //   // 
//   '/setpwd': SetPwdComponent ,
//   '/login': LoginComponent,
//   '/register': RegisterComponent,
//   // '/setpwd': SetPwdComponent
// }
// const currentPath = ref(window.location.hash)
// window.addEventListener('hashchange', () => {
//   currentPath.value = window.location.hash
// })
// const currentView = computed(() => {
//   return routes[currentPath.value.slice(1) || '/'] || NotFound
// })
// const router = VueRouter.createRouter({
//   // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//   history: VueRouter.createWebHashHistory(),
//   routes, // `routes: routes` 的缩写
// })

// 5. 创建并挂载根实例
// const app = Vue.createApp({})
// //确保 _use_ 路由实例使
// //整个应用支持路由。
// app.use(router)

// app.mount('#app')
</script>-->
<script>
  document.title = '烟台开发区实验中学 - 2022级九班'
</script>
<template>
  <router-view></router-view>
  <!-- <component :is="currentView" /> -->
</template>
<!-- <script>
import { ElLoading } from 'element-plus'
const loadingInstance = ElLoading.service({ fullscreen: true })
document.onreadystatechange = function () {
  if (document.readyState == "complete") {
    loadingInstance.close();
  }
}
</script > -->
<!-- <script>
import { ElLoading } from 'element-plus'
const loadingInstance = ElLoading.service({ fullscreen: true })
document.onreadystatechange = function () {
  if (document.readyState == "complete") {
    loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
  }
}
</script > -->